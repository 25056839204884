<template>
  <div class="Account_setting">
    <div class="content">
      <div class="top">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基础信息" name="1">
            <div class="personage">
              <div class="personal_information">
                <el-form :model="showInfo" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">

                  <el-form-item label="当前头像" prop="photo">

                    <img class="avatar" v-if="!$store.state.member.id" src="../../../assets/images/my.jpg" />
                    <img class="avatar" v-else-if="!info.avatar_url" src="../../../assets/images/my.jpg" />
                    <img class="avatar" v-else :src="$config.baseUrl + info.avatar_url" />

                  </el-form-item>
                  <el-form-item label="账号名称" style="width:316px;">

                    <span>{{ showInfo.account }}</span>
                  </el-form-item>

                  <el-form-item v-if="showInfo.member_type == 1" label="姓名" prop="name" :rows="10" style="width:316px;">
                    {{ showInfo.username }}
                  </el-form-item>

                  <el-form-item v-if="showInfo.member_type != 1" label="公司全称" prop="company_name" :rows="10"
                    style="width:316px;">
                    {{ showInfo.company_name }}
                  </el-form-item>

                  <el-form-item v-if="showInfo.member_type != 1" label="企业联系人" prop="company_contacts" :rows="10"
                    style="width:316px;">
                    {{
                      showInfo.company_contacts
                    }}
                  </el-form-item>
                  <el-form-item v-if="showInfo.member_type != 1" label="办公电话" prop="company_tel" :rows="10"
                    style="width:316px;">
                    {{
                      showInfo.company_tel
                    }}
                  </el-form-item>

                  <el-form-item v-if="showInfo.member_type == 1" label="性 别" prop="sex">
                    {{ ['女', '男'][showInfo.sex] }}
                  </el-form-item>

                  <el-form-item v-if="showInfo.member_type == 1" label="年 龄" prop="age">
                    {{ showInfo.agetime }}
                  </el-form-item>

                  <el-form-item v-if="showInfo.member_type == 1" label="行 业" prop="industry">
                    {{ showInfo.industry }}
                  </el-form-item>
                  <el-form-item v-if="showInfo.member_type == 2" label="行 业" prop="company_industry">
                    {{ showInfo.company_industry }}
                  </el-form-item>
                  <el-form-item v-if="showInfo.member_type == 1" label="职业" prop="job">
                    {{ showInfo.job }}
                  </el-form-item>

                  <el-form-item label="邮箱" prop="email">
                    {{ showInfo.email }}
                  </el-form-item>

                  <el-form-item label="所在地区" prop="city_id">{{ showInfo.region }}
                    <!-- {{info.provincess+info.cityss+info.areass+info.streetss}} -->

                  </el-form-item>
                  <!-- <el-form-item
                    v-if="showInfo.member_type != 1"
                    label="经营模式"
                    prop="shop_model"
                  >
                    {{showInfo.shopmodelstr}}
                  </el-form-item> -->

                  <el-form-item v-if="showInfo.member_type != 1" label="主营产品" prop="company_product">
                    {{ showInfo.company_product }}
                  </el-form-item>

                  <el-form-item>
                    <el-button type="primary" @click="toEdit()"
                      style="padding:15px 20px;margin-top:15px;border-radius: 3px;">编辑</el-button>

                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="安全设置" name="2">
            <div class="cor_member">

              <ul class="safe">
                <li>
                  <div class="icon">
                    <img src="../../../assets/images/sj.png" alt="" />

                  </div>
                  <div class="tit">手机号</div>
                  <div class="desc">{{ info.mobile }}

                  </div>

                  <el-button @click="editTel" class="btn" type="primary">修改手机号</el-button>

                </li>

                <li>
                  <div class="icon">
                    <img src="../../../assets/images/mm.png" alt="" />

                  </div>
                  <div class="tit">登录密码</div>
                  <div class="desc">定期更换密码有助于账号安全</div>

                  <el-button class="btn" type="primary" @click="editPassword">修改登录密码</el-button>

                </li>
              </ul>

            </div>
          </el-tab-pane>
          <el-tab-pane label="实名认证" name="3">
            <div class="cor_member" v-if="license == 0 || license == 3">

              <div v-if='license == 3'>
                <el-steps style="margin:0 auto;width:450px;margin-top:0px" :space="200" :active="2"
                  finish-status="success" process-status='error'>
                  <el-step title="提交审核"></el-step>
                  <el-step title="审核中"></el-step>
                  <el-step title="审核不通过"></el-step>
                </el-steps>

                <el-alert style="padding:20px ;margin:20px" :closable='false' title="请修改以下问题再提交：" type="warning"
                  :description="mem__reason" show-icon>
                </el-alert>

              </div>

              <el-form v-if="this.$store.state.member.type == 1" :model="setAttestation1" status-icon
                :rules="setAttestation1_rules" ref="setAttestation1" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="usernames">
                  <el-input type="text" v-model="setAttestation1.usernames"></el-input>
                </el-form-item>
                <el-form-item class="" label="身份证正面" prop="previewImg1">

                  <div class='Idcar'>
                    <dl>
                      <dt class="sfz1">
                        <input type="file" @change="upload1($event)" /><img :src="setAttestation1.previewImg1">
                      </dt>
                      <dd>请上传手持身份证正面图</dd>
                    </dl>

                  </div>
                </el-form-item>
                <el-form-item class="" label="身份证反面" prop="previewImg2">
                  <div class='Idcar'>

                    <dl>
                      <dt class="sfz2"> <input type="file" @change="upload2($event)" /><img
                          :src="setAttestation1.previewImg2"></dt>
                      <dd>请上传手持身份证反面图</dd>
                    </dl>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm3('setAttestation1')"
                    style="padding:15px 20px;margin-top:15px;border-radius: 3px;width:150px">提交</el-button>
                </el-form-item>
              </el-form>

              <el-form v-else :model="setAttestation2" status-icon :rules="setAttestation2_rules" ref="setAttestation2"
                label-width="100px" class="demo-ruleForm">

                <el-form-item label="公司名称" prop="company_name" style="width:316px;">
                  <el-input type="text" v-model="setAttestation2.company_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人姓名" prop="contacts" style="width:316px;">
                  <el-input type="text" v-model="setAttestation2.contacts" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="办公电话" prop="contact_number" style="width:316px;">
                  <el-input type="text" v-model="setAttestation2.contact_number" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item class="" label="身份证正面" prop="previewImg1">
                  <div class='Idcar'>
                    <dl>
                      <dt class="sfz1"> <input type="file" @change="upload3($event)" /><img
                          :src="setAttestation2.previewImg1"></dt>
                      <dd>请上传法人身份证正面图</dd>
                    </dl>

                  </div>
                </el-form-item>
                <el-form-item class="" label="身份证反面" prop="previewImg2">
                  <div class='Idcar'>

                    <dl>
                      <dt class="sfz2"> <input type="file" @change="upload4($event)" /><img
                          :src="setAttestation2.previewImg2"></dt>
                      <dd>请上传身份证反面图</dd>
                    </dl>
                  </div>
                </el-form-item>
                <el-form-item label="营业执照" prop="previewImg3">
                  <div class="businesslicense">
                    <input type="file" @change="upload5($event)" /><img :src="setAttestation2.previewImg3">
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm4('setAttestation2')"
                    style="padding:15px 20px;margin-top:15px;border-radius: 3px;width:150px">提交</el-button>
                </el-form-item>
              </el-form>
            </div>

            <div class=" " v-else-if="license == 1">
              <el-steps :space="200" :active="2" finish-status="success"
                style="margin:0 auto;width:450px;margin-top:50px">
                <el-step title="提交审核"></el-step>
                <el-step title="审核中"></el-step>
                <el-step title="审核结果"></el-step>
              </el-steps>

              <el-col style="margin-top:80px">

                <el-result icon="warning" title="审核中">
                  <template slot="extra">

                  </template>
                </el-result>
              </el-col>
            </div>
            <div class=" " v-else-if="license == 2">

              <el-steps style="margin:0 auto;width:450px;margin-top:50px" :space="200" :active="3"
                finish-status="success">
                <el-step title="提交审核"></el-step>
                <el-step title="审核中"></el-step>
                <el-step title="审核通过"></el-step>
              </el-steps>
              <el-col style="margin-top:80px">
                <el-result icon="success" title="已认证" subTitle="您已通过实名认证">
                  <template slot="extra">

                  </template>
                </el-result>
              </el-col>
            </div>

          </el-tab-pane>
        </el-tabs>
      </div>

      <el-dialog title="修改信息" :visible.sync="showInfo.dialogVisible" width="70%">
        <div class="personage">
          <div class="personal_information">
            <el-form ref="setInfo" :rules="setInfo_rules" :model="setInfo" label-width="100px">
              <el-form-item label="当前头像" prop="avatar">

                <el-upload class="avatar-uploader" :headers="headers" :action="baseUrl + '/home/files/memberhead'"
                  :show-file-list="false" :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1"
                  name="avatar">
                  <img v-if="info.avatar_url" :src="baseUrl + info.avatar_url" class="avatar"
                    style="border:1px solid #ccc;">
                  <div style="border:1px solid #ccc;" v-else><img v-if="imageUrl" :src="imageUrl" class="avatar"
                      style="border:1px solid #ccc;">
                    <i v-else class="el-icon-plus avatar-uploader-icon">

                    </i>
                  </div>
                </el-upload>

              </el-form-item>
              <el-form-item label="账号名称">

                <span>{{ setInfo.account }}</span>
              </el-form-item>

              <el-form-item v-if="setInfo.member_type == 1" label="姓名" prop="username" :rows="10" style="width:316px;">
                <el-input type="text" v-model="setInfo.username" autocomplete="off" placeholder="请输入姓名"></el-input>
              </el-form-item>

              <el-form-item v-if="setInfo.member_type != 1" label="公司全称" prop="company_name" :rows="10"
                style="width:316px;">
                <el-input type="text" v-model="setInfo.company_name" autocomplete="off" placeholder="请输入公司全称"></el-input>
              </el-form-item>

              <el-form-item v-if="setInfo.member_type != 1" label="办公电话" prop="company_tel" :rows="10"
                style="width:316px;">
                <el-input type="text" v-model="setInfo.company_tel" autocomplete="off" placeholder="请输入办公电话"></el-input>
              </el-form-item>
              <el-form-item v-if="showInfo.member_type != 1" label="企业联系人" prop="company_contacts" :rows="10"
                style="width:316px;">
                <el-input type="text" v-model="setInfo.company_contacts" autocomplete="off"
                  placeholder="请输入企业联系人"></el-input>
              </el-form-item>
              <el-form-item v-if="setInfo.member_type == 1" label="性 别" prop="sex" :rows="10" style="width:316px;">
                <select v-model="setInfo.sex" class="select">
                  <option :value="item.label" v-for="item in setInfo.listDataSex" :key="item.label">
                    {{ item.value }}
                  </option>
                </select>
              </el-form-item>

              <el-form-item v-if="setInfo.member_type == 1" label="年 龄" prop="agetime">
                <select v-model="setInfo.agetime" class="select">
                  <option :value="item.value" v-for="item in setInfo.listDataAge" :key="item.label">
                    {{ item.value }}
                  </option>
                </select>
              </el-form-item>

              <el-form-item label="行 业" prop="industry" v-if="setInfo.member_type == 1">
                <select v-model="setInfo.industry" class="select">
                  <option :value="item.value" v-for="item in setInfo.listDataIndustry" :key="item.label">
                    {{ item.value }}
                  </option>
                </select>
              </el-form-item>
              <el-form-item label="行 业" prop="company_industry" v-if="setInfo.member_type == 2">
                <select v-model="setInfo.company_industry" class="select">
                  <option :value="item.value" v-for="item in setInfo.listDataIndustry" :key="item.label">
                    {{ item.value }}
                  </option>
                </select>
              </el-form-item>

              <el-form-item v-if="setInfo.member_type == 1" label="职业" prop="job">
                <select v-model="setInfo.job" class="select">
                  <option :value="item.value" v-for="item in setInfo.listDataJob" :key="item.label">
                    {{ item.value }}
                  </option>
                </select>
              </el-form-item>

              <el-form-item label="邮箱" prop="email">
                <el-input type="text" v-model="setInfo.email" autocomplete="off" placeholder="请输入邮箱">
                </el-input>
              </el-form-item>

              <el-form-item label="所在地区" prop="city_id">
                <el-select v-model="setInfo.province_id" filterable placeholder="请选择" @change="getCity(true)">
                  <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>

                <el-select v-model="setInfo.city_id" filterable placeholder="请选择" @change="getArea(true)">
                  <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>

                <!-- <el-select
                  v-model="setInfo.area_id"
                  filterable
                  placeholder="请选择"
                  @change="getStreet(true)"
                >
                  <el-option
                    v-for="item in options3"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select> -->

                <!-- <el-select
                  v-model="setInfo.street_id"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options4"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select> -->
              </el-form-item>
              <!-- <el-form-item
                v-if="setInfo.member_type != 1"
                label="经营模式"
                prop="shop_model"
              >
                <select
                  v-model="setInfo.shop_model"
                  class="select"
                >
                  <option
                    :value="item.label"
                    v-for="item in listData3"
                    :key="item.label"
                  >
                    {{ item.value }}
                  </option>
                </select>
              </el-form-item> -->

              <el-form-item v-if="setInfo.member_type != 1" label="主营产品" prop="company_product">
                <el-cascader style="width:50%" v-model="setInfo.zypro" :options="listDataSort"></el-cascader>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submitForm_info('setInfo')"
                  style="padding:15px 20px;margin-top:15px;border-radius: 3px;">保存修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="修改手机号" :visible.sync="setphone.dialogVisible" width="400px">

        <div class="yzm">
          <el-form class="">
            <!-- <el-form-item style=' text-align:center'> <img
                style='width:90px;margin:0 auto'
                @click="$router.push('/')"
                src="../../../assets/images/yzm.png"
                alt=""
              /></el-form-item>

            <el-form-item>
              <p style='font-weight:bold;margin-bottom:20px'> 使用手机短信验证</p>
            </el-form-item> -->
            <el-form-item>
              <p style=' margin-bottom:20px'> 当前手机号： {{ info.mobile }}</p>
            </el-form-item>
            <el-form-item label=" 设置绑定手机">

              <el-input style='width:70%;margin-bottom:10px' v-model="setphone.mobile" placeholder=""></el-input>
            </el-form-item>

            <el-form-item>
              <div class="hqyzm">

                <el-input style='margin-right:10px' v-model="setphone.code" :clearBtn="true" :disabled="false"></el-input>
                <el-button type="info" @click="getCode()" :disabled="setphone.yzmDisabled">{{ setphone.codeInput
                }}</el-button>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button style="width:100%;margin-top:20px" type="primary" @click="register()">提交</el-button>

            </el-form-item>
          </el-form>
        </div>

      </el-dialog>
      <el-dialog title="修改密码" :visible.sync="setPassword.dialogVisible" width="400px">
        <el-form :model="setPassword" :rules="rules_setPw" ref="setPassword" label-width="0" class="demo-ruleForm"
          style="border: none">
          <el-form-item prop="mobile" style="margin-bottom: 25px"> 当前手机号：{{ info.mobile }}
            <!-- <el-input
              type="text"
              v-model="setPassword.mobile"
              placeholder="手机号"
            /> -->
          </el-form-item>
          <el-form-item style="margin-bottom: 25px" prop="code">
            <el-col :span="16">
              <el-input style="width: 100%；" v-model="setPassword.code" placeholder="验证码" :clearBtn="true"
                :disabled="false" />
            </el-col>
            <el-col :span="8">
              <el-button type="info" style="
                      padding: 0 0.1rem;
                      width: 100%;
                      height: 40px;
                      line-height:40px;
                      
                      float: right;
                    " @click="getCode2()" :disabled="setPassword.yzmDisabled">
                {{ setPassword.codeInput }}
              </el-button>
            </el-col>
          </el-form-item>
          <el-form-item prop="password1" style="margin-bottom: 25px">
            <el-input type="password" v-model="setPassword.password1" placeholder="密码（须带字母的8位数）" />
          </el-form-item>
          <el-form-item prop="password2" style="margin-bottom: 25px">
            <el-input type="password" v-model="setPassword.password2" placeholder="确认密码" />
          </el-form-item>
          <el-form-item style="margin-bottom: 25px">
            <el-button style='width:100%' type="primary" @click="submit_setPw('setPassword')" ref="btn">提交</el-button>
          </el-form-item>
        </el-form>

      </el-dialog>
    </div>
  </div>
</template>

<script type="text/javascript">
import identity from "@/assets/utils/identity";
import { getToken } from '@/assets/config/token';
export default {
  data() {
    return {
      photo: "",
      block: false,
      listDataSort: [
        // {
        //   value: "zhinan",
        //   label: "指南",
        //   children: [
        //     {
        //       value: "shejiyuanze",
        //       label: "设计原则",
        //       children: [{}]
        //     }
        //   ]
        // }
      ],
      setphone: {
        dialogVisible: false,
        show: true,
        mobile: "",
        code: "",
        codeInput: "发送验证码",
        yzmDisabled: false,
        checkbox1: false,
        time: 60,
        isVisible: false
      },
      setPassword: {
        dialogVisible: false,
        show: true,
        index: "1",
        account: "",

        codeInput: "发送验证码",

        yzmDisabled: false,
        checkbox1: false,
        time: 60,
        mobile: "",
        code: "",
        password1: "",
        password2: ""
      },
      rules_setPw: {
        code: [{ required: true, message: "请输入验证", trigger: "change" }],
        password1: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            pattern: this.$validate.password,
            message: "密码必须带字母的8位数",
            trigger: "change"
          }
        ],
        password2: [
          { required: true, message: "请再次输入密码", trigger: "change" }
        ]
      },
      isVisible: false,

      info: {},
      options: [],
      options2: [],
      options3: [],
      options4: [],
      baseUrl: this.$config.baseUrl,

      company_license: [],
      company_card: [],
      contact_number: "",
      bank_name: "",
      bank_num: "",
      license: "",

      activeName: "1",

      ageData: [],

      imageUrl: "",

      listData3: [
        {
          label: 1,
          value: "自有品牌"
        },
        {
          label: 2,
          value: "代理"
        }
      ],
      showInfo: {
        dialogVisible: false,
        sex: "",
        shopmodelstr: "",
        shop_model: undefined,
        memberId: "",
        account: "",
        level: "",
        company_name: "",
        company_tel: "",
        company_logo: "",
        company_logo_img: "",
        contact_name: "",
        date: null,
        isVisible1: false,
        member_type: "",
        avatar: "",
        previewImg: undefined,
        company_industry: "",
        industry: "",
        industry_id: "",
        job: "",
        job_id: "",
        region: " ",
        showPopup: false,
        showPopup1: false,
        province_id: "",
        city_id: "",
        area_id: "",
        province: [],
        city: [],
        country: [],
        town: [],

        sortData: [],
        username: "",
        menuItems: [],
        agetime: "",

        option: "",
        isVisible: false,
        isVisible_industry: false,
        isVisible_job: false,
        isVisibleSex: false,
        isVisibleAge: false,
        email: "",
        company_url: "",
        company_product: "",
        zypro: undefined,
        listDataJob: [],
        listDataIndustry: [],
        listData: [[]],
        isVisible_sort: false,
        defaultValueDataSort: null,

        optionlabel: "",
        defaultValueData1: [],
        defaultValueData_jyms: null,
        shop_logo_img: undefined,
        shop_logo: "",

        isVisible_jyms: false,

        listDataSex: [
          {
            label: "1",
            value: "男"
          },
          {
            label: "0",
            value: "女"
          }
        ],
        listDataAge: [
          {
            label: "0",
            value: "19岁以下"
          },
          {
            label: "1",
            value: "20-29"
          },
          {
            label: "2",
            value: "30-39"
          },
          {
            label: "3",
            value: "40-49"
          },
          {
            label: "4",
            value: "50-59"
          },
          {
            label: "5",
            value: "60岁以上"
          }
        ]
      },
      setInfo_rules: {
        avatar: [
          {
            required: true,
            message: "请上传照片",
            trigger: "blur"
          }
        ],
        company_name: [
          {
            required: true,
            message: "请输入公司全称",
            trigger: "blur"
          }
        ],
        company_tel: [
          {
            required: true,
            message: "请输入办公电话",
            trigger: "blur"
          },
          {
            pattern: this.$validate.mobile,
            message: "请输入正确的电话",
            trigger: "change"
          }
        ],
        username: [
          {
            required: true,
            message: "请填写姓名",
            trigger: "blur"
          }
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur"
          }
        ],
        agetime: [
          {
            required: true,
            message: "选择年龄段",
            trigger: "blur"
          }
        ],
        industry: [
          {
            required: true,
            message: "请填写行业",
            trigger: "blur"
          }
        ],
        company_industry: [
          {
            required: true,
            message: "请填写行业",
            trigger: "blur"
          }
        ],
        job: [
          {
            required: true,
            message: "请填写职业",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "请填写邮箱",
            trigger: "blur"
          },
          {
            pattern: this.$validate.email,
            message: "请填写正确的邮箱",
            trigger: "blur"
          }
        ],
        city_id: [
          {
            required: true,
            message: "请填写区域",
            trigger: "blur"
          }
        ],
        // shop_model: [
        //   {
        //     required: true,
        //     message: "请填写经营模式",
        //     trigger: "blur"
        //   }
        // ],
        zypro: [
          {
            required: true,
            message: "请选择主营产品",
            trigger: "blur"
          }
        ]
      },
      setInfo: {},
      ruleForm: {
        account: localStorage.getItem("account"),
        avatar: "",
        name: "",
        age: "20",
        gender: "0",
        industry: "",
        company_industry: "",
        profession: "",
        region: ""
      },
      mem__reason: "",

      setAttestation1: {
        usernames: "",
        previewImg1: undefined,
        previewImg2: undefined
      },
      setAttestation2: {
        company_name: "",
        contacts: "",
        contact_number: "",
        previewImg1: undefined,
        previewImg2: undefined,
        previewImg3: undefined,
        company_license: undefined
      },
      setAttestation1_rules: {
        usernames: [
          {
            required: true,
            message: "请输入真实姓名",
            trigger: "blur"
          }
        ],
        previewImg1: [
          {
            required: true,
            message: "请上传身份证正面",
            trigger: "blur"
          }
        ],
        previewImg2: [
          {
            required: true,
            message: "请上传身份证反面",
            trigger: "blur"
          }
        ]
      },
      setAttestation2_rules: {
        company_name: [
          {
            required: true,
            message: "公司名称",
            trigger: "blur"
          }
        ],
        contacts: [
          {
            required: true,
            message: "联系人",
            trigger: "blur"
          }
        ],
        contact_number: [
          {
            required: true,
            message: "办公电话",
            trigger: "blur"
          }
        ],
        company_contacts: [
          {
            required: true,
            message: "企业联系人",
            trigger: "blur"
          }
        ],
        previewImg1: [
          {
            required: true,
            message: "请上传身份证正面",
            trigger: "blur"
          }
        ],
        previewImg2: [
          {
            required: true,
            message: "请上传身份证反面",
            trigger: "blur"
          }
        ],
        previewImg3: [
          {
            required: true,
            message: "请上传营业执照",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }

    this.getCategory();
    //查询身份认证
    this.$post("home/user/licenseStatus", {
      member_id: this.$store.state.member.id
    }).then(res => {
      console.log("licenseStatus", res);
      if (res.member_type == 1) {
        this.license = res.status;
        this.getgrrz();
      }
      if (res.member_type == 2) {
        this.license = res.company_status;
        this.getqyrz();
      }
    });

    this.getIndustry();
    this.getJob();
  },
  methods: {
    //获取信息
    getgrrz() {
      this.$post("home/user/info", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.setAttestation1.usernames = res.usernames;
        this.setAttestation1.mem__reason = res.mem__reason;
        console.log("hyxx", res);

        if (res.cardPic[0]) {
          this.setAttestation1.previewImg1 =
            this.$config.baseUrl + res.cardPic[0].files_path;
          this.company_card[0] = res.cardPic[0].files_id;
        } else {
          this.setAttestation1.previewImg1 = undefined;
        }

        if (res.cardPic[1]) {
          this.setAttestation1.previewImg2 =
            this.$config.baseUrl + res.cardPic[1].files_path;
          this.company_card[1] = res.cardPic[1].files_id;
        } else {
          this.setAttestation1.previewImg2 = undefined;
        }
      });
    },
    //获取信息
    getqyrz() {
      this.$post("home/user/info", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.setAttestation2.company_name = res.company_name;
        this.setAttestation2.contacts = res.contacts;
        this.setAttestation2.contact_number = res.contact_number;

        console.log("hyxx", res);
        this.setAttestation2.previewImg1 = res.cardPic[0]
          ? this.$config.baseUrl + res.cardPic[0].files_path
          : undefined;
        this.setAttestation2.previewImg2 = res.cardPic[1]
          ? this.$config.baseUrl + res.cardPic[1].files_path
          : undefined;
        this.setAttestation2.previewImg3 = res.licensePic[0]
          ? this.$config.baseUrl + res.licensePic[0].files_path
          : undefined;
        this.company_card[0] = (res.cardPic[0] || {}).files_id;
        this.company_card[1] = (res.cardPic[1] | {}).files_id;
        this.setAttestation2.company_license = (
          res.licensePic[0] || {}
        ).files_id;
      });
    },
    getCategory() {
      //获取品类 listDataSort
      this.$get("home/goodsCategory").then(res => {
        res.forEach(a => {
          this.listDataSort.push({
            value: a.id,
            label: a.cate_name,
            children: a.cc.map(b => {
              return { value: b.id, label: b.cate_name };
            })
          });
        });
        this.getInfo();
      });
    },
    //修改秘密
    submit_setPw(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.setPassword.password1 != this.setPassword.password2) {
            this.$message("密码不一致");
            return;
          }
          //提交
          this.$post("home/user/getpwd", {
            mobile: this.info.mobile,
            password: this.setPassword.password1,
            code: this.setPassword.code
          }).then(res => {
            console.log("更改密码", res);
            this.$message.success("修改成功！");

            this.$post("/home/user/logout", {
              member_id: this.$store.state.member.id,
              member_token: getToken()
            }).then(() => {
              identity.logout();
            });
          });
        } else {
          // console.log("error submit!!")
          return false;
        }
      });
    },
    //请求验证码
    getCode2() {
      this.$post("home/user/code", { mobile: this.info.mobile }).then(res => {
        //检查手机号是否存在
        if (res.status == 0) {
          this.$message.warning("电话不存在！");
          return;
        }
        // 检获取验证码

        // if (res.code == 60204) {
        //   this.$message.warn("发送过于频繁，请1个小时候再试");
        //   return;
        // }
        this.setPassword.time = 60;
        this.timer2();
      });
    },
    //请求验证码
    getCode() {
      this.$post("home/user/find_mobile", {
        mobile: this.setphone.mobile
      }).then((res) => {
        //检查手机号是否存在
        // if (res.status!=0) {
        //   this.$message.warning("电话已存在！");
        //   return;
        // }
        // 检查手机号是否存在
        this.$post("home/user/code", { mobile: this.setphone.mobile }).then(
          res => {
            // 检获取验证码

            this.setphone.time = 60;
            this.timer();
          }
        );
      });
    },
    //1分钟倒计时
    timer2() {
      if (this.setPassword.time > 0) {
        this.setPassword.yzmDisabled = true;
        this.setPassword.time--;
        this.setPassword.codeInput = this.setPassword.time + "秒";
        setTimeout(this.timer2, 1000);
      } else {
        this.setPassword.time = 0;
        this.setPassword.codeInput = "发送验证码";
        this.setPassword.yzmDisabled = false;
      }
    },
    //1分钟倒计时
    timer() {
      if (this.setphone.time > 0) {
        this.setphone.yzmDisabled = true;
        this.setphone.time--;
        this.setphone.codeInput = this.setphone.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.setphone.time = 0;
        this.setphone.codeInput = "发送验证码";
        this.setphone.yzmDisabled = false;
      }
    },

    //提交
    register() {
      if (!this.setphone.code) {
        this.$message("请填写验证码");
        return;
      }
      this.$post("home/user/infoupdate", {
        //修改会员资料
        member_id: this.$store.state.member.id,
        mobile: this.setphone.mobile,
        code: this.setphone.code,
        member_type: this.$store.state.member.type
      }).then(res => {
        console.log(res);
        this.setphone.dialogVisible = true;
      });
    },

    editTel() {
      this.setphone.dialogVisible = true;
    },
    editPassword() {
      this.setPassword.dialogVisible = true;
    },

    //跳转编辑
    toEdit() {
      this.setInfo = Object.assign({}, this.showInfo);
      this.showInfo.dialogVisible = true;

      this.getProvince();
      this.getCity();
      this.getArea();
      this.getStreet();
    },
    //获取信息
    getInfo() {
      this.$post("home/user/info", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.mem__reason = res.mem__reason;
        this.info = res;
        this.showInfo.shopmodelstr = res.shopmodelstr;
        this.showInfo.memberId = res.member_id;
        this.showInfo.username = res.username;
        this.showInfo.account = res.account;
        this.showInfo.level = res.level;
        this.showInfo.city_id = res.city;
        this.showInfo.province_id = res.province;
        this.showInfo.area_id = res.areas;
        this.showInfo.street_id = res.street;
        this.showInfo.email = res.email;
        this.showInfo.agetime = res.agetime;
        this.showInfo.sex = res.sex;
        this.showInfo.company_product = res.zyprostr;
        this.showInfo.industry = res.industry;
        this.showInfo.company_industry = res.company_industry;
        this.showInfo.industry_id = res.industry_id;
        this.showInfo.company_name = res.company_name;
        this.showInfo.company_tel = res.company_tel;
        this.showInfo.company_url = res.company_url;
        this.showInfo.company_contacts = res.company_contacts;

        this.showInfo.contacts = res.contacts;
        this.showInfo.job = res.job;
        this.showInfo.job_id = res.job_id;
        this.showInfo.shop_model = parseInt(res.company_management_model);
        if (res.company_management_model == 1) {
          this.showInfo.shopmodelstr = "自有品牌";
        } else {
          this.showInfo.shopmodelstr = "代理";
        }

        console.log("shop_model1", this.showInfo.shop_model);
        this.showInfo.avatar = res.avatar;
        this.showInfo.member_type = res.member_type;
        this.showInfo.address = res.address;
        this.showInfo.region = (res.provincess || "") + (res.cityss || "");

        this.showInfo.previewImg = this.$config.baseUrl + res.avatar_url;
        if (this.showInfo.member_type != 1) {
          if (this.showInfo.zypro.length) {
            console.log('zypro', this.showInfo.zypro)
            this.showInfo.zypro = JSON.parse(res.zypro);
          }

          let sort_0 = "";
          let sort_1 = "";
          console.log("listDataSort", this.listDataSort);

          this.listDataSort.forEach(i => {
            if (i.value == this.showInfo.zypro[0]) {
              sort_0 = i.label;
              i.children.forEach(o => {
                if (o.value == this.showInfo.zypro[1]) {
                  sort_1 = o.label;
                }
              });
            }
          });

          if (sort_0 && sort_1) {
            this.showInfo.company_product = sort_0 + " / " + sort_1;
          }

          this.showInfo.company_logo_img =
            this.$config.baseUrl + (res.company_logo[0] || {}).files_path;
          this.showInfo.company_logo = (res.company_logo[0] || {}).files_id;
        }
        this.showInfo.avatar = res.avatar;
      });
    },
    //职业
    getJob() {
      this.$get("/index.php/admin/hyzy").then(res => {
        this.showInfo.listDataJob = res.data.map(item => {
          return {
            label: item.id,
            value: item.value
          };
        });
      });
    },
    //获取行业
    getIndustry() {
      this.$get("index.php/admin/hyzy", { type: 1, page: 1, limit: 100 }).then(
        res => {
          console.log("行业", res.data);
          res.data.forEach(item => {
            this.showInfo.listDataIndustry.push({
              label: item.id,
              value: item.value
            });
          });

          console.log("listData", this.listDataIndustry);
        }
      );
    },

    getProvince() {
      this.$post("home/regions/index", {
        level: 1,
        pid: 0
      }).then(res => {
        this.options = res;
      });
    },
    getCity(isChange) {
      if (isChange) {
        this.setInfo.city_id = undefined;
        this.setInfo.area_id = undefined;
        this.setInfo.street_id = undefined;
        this.options2 = [];
        this.options3 = [];
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 2,
        pid: this.setInfo.province_id
      }).then(res => {
        this.options2 = res;
      });
    },
    getArea(isChange) {
      if (isChange) {
        this.setInfo.area_id = undefined;
        this.setInfo.street_id = undefined;
        this.options3 = [];
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 3,
        pid: this.setInfo.city_id
      }).then(res => {
        this.options3 = res;
      });
    },
    getStreet(isChange) {
      if (isChange) {
        this.setInfo.street_id = undefined;
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 4,
        pid: this.setInfo.area_id
      }).then(res => {
        this.options4 = res;
      });
    },
    //上传身份证正面(个人)
    upload1(e) {
      if (e.target.files[0].size / 1024 >= 2048) {
        this.$message("图片不能超过2M");
        return;
      }
      let img = new FormData();

      img.append("cardPic", e.target.files[0]);

      this.$post("home/files/cardPic", img).then(res => {
        this.company_card[0] = res.id;
        this.$util.imgToBase64(e.target.files[0]).then(res => {
          this.setAttestation1.previewImg1 = res.result;
        });
      });
    },

    //上传身份证反面（个人）
    upload2(e) {
      if (e.target.files[0].size / 1024 >= 2048) {
        this.$message("图片不能超过2M");
        return;
      }
      let img = new FormData();
      img.append("cardPic", e.target.files[0]);
      this.$post("home/files/cardPic", img).then(res => {
        this.company_card[1] = res.id;
        this.$util.imgToBase64(e.target.files[0]).then(res => {
          this.setAttestation1.previewImg2 = res.result;
        });
        console.log("company_card", this.company_card);
      });
    },
    //上传身份证正面(企业)
    upload3(e) {
      if (e.target.files[0].size / 1024 >= 2048) {
        this.$message("图片不能超过2M");
        return;
      }
      let img = new FormData();

      img.append("cardPic", e.target.files[0]);

      this.$post("home/files/cardPic", img).then(res => {
        this.company_card[0] = res.id;
        this.$util.imgToBase64(e.target.files[0]).then(res => {
          this.setAttestation2.previewImg1 = res.result;
        });
      });
    },

    //上传身份证反面（企业）
    upload4(e) {
      if (e.target.files[0].size / 1024 >= 2048) {
        this.$message("图片不能超过2M");
        return;
      }
      let img = new FormData();
      img.append("cardPic", e.target.files[0]);
      this.$post("home/files/cardPic", img).then(res => {
        this.company_card[1] = res.id;
        this.$util.imgToBase64(e.target.files[0]).then(res => {
          this.setAttestation2.previewImg2 = res.result;
        });
        console.log("company_card", this.company_card);
      });
    },
    //上传营业执照
    upload5(e) {
      if (e.target.files[0].size / 1024 >= 2048) {
        this.$message("图片不能超过2M");
        return;
      }
      let img = new FormData();
      img.append("cardPic", e.target.files[0]);
      this.$post("home/files/cardPic", img).then(res => {
        // this.company_license[0] = res.id;
        this.company_license = res.id;
        this.setAttestation2.company_license = res.id;

        this.$util.imgToBase64(e.target.files[0]).then(res => {
          this.setAttestation2.previewImg3 = res.result;
        });
        console.log("company_license", this.company_license);
      });
    },

    handleAvatarSuccess1(res, file) {
      // console.log(res, file)
      this.setInfo.avatar = res.data.id;
      this.imageUrl = URL.createObjectURL(file.raw);
      // console.log(this.imageUrl)
    },
    beforeAvatarUpload1(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let arr = ["image/jpeg", "image/png", "image/bmp"];
      var rs = arr.find(function (value) {
        if (value === file.type) {
          //则包含该元素
          return true;
        } else {
          return false;
        }
      });
      if (!rs) {
        rs = false;
        this.$message.error("上传头像图片只能是 JPG、PNG、BMP 格式!");
      }

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      //return isJPG && isLt2M;
      return rs && isLt2M;
    },

    submitForm_info(formName) {
      console.log("setInfo", this.setInfo);
      this.$refs[formName].validate(valid => {
        if (valid) {
          let company_model = "";
          if (this.setInfo.shop_model == "1") company_model = "自营";
          if (this.setInfo.shop_model == "2") company_model = "代理";
          let data = {
            zyprostr: this.setInfo.company_product,
            shopmodelstr: this.setInfo.company_model,
            username: this.setInfo.username,
            member_id: this.setInfo.memberId,
            member_type: this.setInfo.member_type,
            agetime: this.setInfo.agetime,
            avatar: this.setInfo.avatar,
            sex: this.setInfo.sex,
            industry_id: this.setInfo.industry_id,
            job_id: this.setInfo.job_id,
            company_industry: this.setInfo.company_industry,
            company_contacts: this.setInfo.company_contacts,

            industry: this.setInfo.industry,
            job: this.setInfo.job,
            email: this.setInfo.email,
            company_name: this.setInfo.company_name,
            company_tel: this.setInfo.company_tel,
            // company_management_model: this.setInfo.shop_model,
            zypro: this.setInfo.zypro,
            company_url: this.setInfo.company_url,
            company_logo: this.setInfo.company_logo,
            address: this.setInfo.address,
            city: this.setInfo.city_id,
            province: this.setInfo.province_id,
            areas: this.setInfo.area_id || 0,
            street: this.setInfo.street_id || 0
          };
          console.log("data", data);
          this.$post("home/user/infoupdate", data).then(res => {
            console.log(res);
            this.$message.success("修改成功");

            this.showInfo.dialogVisible = false;
            this.getInfo();
            // location.reload();
          });
        }
      });
    },
    submitForm3(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            member_id: this.$store.state.member.id,
            usernames: this.setAttestation1.usernames,
            card: this.company_card,

            type: "1"
          };
          console.log("data", data);

          this.$post("home/user/license", data).then(res => {
            console.log(res);
            this.$message.success("提交认证审核");
            location.reload();
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },

    submitForm4(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            company_member_id: this.$store.state.member.id,
            company_license: this.setAttestation2.company_license.toString(),
            company_name: this.setAttestation2.company_name,
            contacts: this.setAttestation2.contacts,
            contact_number: this.setAttestation2.contact_number,
            company_card: this.company_card.join(","),
            type: "2"
          };
          this.$post("home/user/license", data).then(res => {
            this.$message.success("提交认证审核");
            location.reload();
          });
        } else {
          return false;
        }
      });
    },

    handleClick(tab, event) {
      console.log(tab, event);
    }
  },
  computed: {
    headers() {
      return {
        token: getToken()
      };
    }
  },
  created() { }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
@import "@/assets/styles/common";

.between {
  @include space-between;
}

::v-deep .el-alert--warning.is-light {
  padding: 10px;
}

.center {
  text-align: center;
}

.yzm .el-form-item {
  text-align: center;
  margin: 0 !important;

  .hqyzm {
    @include space-between;
  }
}

.safe {
  li {
    color: #999;
    float: left;
    border: 1px solid #eee;
    width: 260px;
    height: 260px;
    padding: 20px;
    margin: 0 21px 21px 0;
    background: #fff;

    .icon {
      margin: 0 auto 20px;

      text-align: center;

      overflow: hidden;

      img {
        width: 84px;
        height: 70px;
      }
    }

    .tit {
      margin-bottom: 10px;
      font-size: 20px;

      letter-spacing: -0.31px;
      text-align: center;
      text-align: center;
    }

    .desc {
      height: 18px;
      margin-bottom: 20px;
      font-size: 14px;

      letter-spacing: -0.22px;
      line-height: 18px;
      text-align: center;
    }

    .btn {
      width: 100%;
      margin: 0 auto;
    }
  }
}

.logo {
  background: url("../../../assets/images/tu.png") no-repeat #eee center center;
  background-size: 100% 100%;
  position: relative;
  left: 0;
  right: 0;

  border: #fff !important;

  p {
    text-align: center;
  }

  width: 120px;
  height: 120px;
  border-radius: 50%;

  input {
    border: #fff !important;
    position: absolute;
    left: 0;
    z-index: 100;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  img {
    border-radius: 50%;
    border: 0 !important;
    position: absolute;
    z-index: 10;
    left: 0;

    width: 100%;
    height: 100%;
  }
}

.select {
  height: 40px;
  height: 40px;

  width: 100%;
  padding: 0 30px 0 10px;
  border-radius: 4px;
  border: 1px solid #dddfe6;
}

.businesslicense {
  background: url("../../../assets/images/tu.png") no-repeat #eee center center;
  background-size: 100px 70px;
  margin-top: 10px;
  line-height: 90px;
  width: 500px;
  height: 280px;
  position: relative;
  @include center;

  color: #666;

  input {
    border: 1px solid #dedcdc;
    position: absolute;
    z-index: 100;
    opacity: 0;
    width: 100%;
    height: 280px;
  }

  img {
    width: 100%;
    height: 280px;
  }
}

.Idcar {
  width: 80%;

  dl {
    float: left;
    margin-right: 50px;

    text-align: center;

    .sfz1 {
      background: url("../../../assets/images/sfz1.jpg") no-repeat;
      background-size: 100% 100%;
    }

    .sfz2 {
      background: url("../../../assets/images/sfz2.jpg") no-repeat;
      background-size: 100% 100%;
    }

    dt {
      position: relative;
      left: 0;
      right: 0;

      width: 300px;
      height: 180px;
      background: #eee;

      input {
        position: absolute;
        z-index: 100;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }

    img {
      width: 100%;
      height: 180px;
    }
  }
}

.Account_setting {
  width: 100%;
  background: #fff;
}

.el-form-item {
  margin: 0 0 30px 0 !important;
}

.el-select-dropdown__item {
  padding: 0 15px !important;
}

.el-input__inner {
  padding: 0 !important;
  text-indent: 15px !important;
  border-radius: 0 !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #e94c15;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
  border: 1px solid #eee;
}
</style>
